@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
  }
  .login-field {
    @apply appearance-none rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm;
  }
  .rounded-card {
    @apply cursor-pointer border h-full bg-white  hover:shadow-md bg-opacity-75 p-8 rounded-full overflow-hidden text-center relative;
  }
  .nav-link {
    @apply inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500
    ease-in-out transform rounded-lg hover:bg-gray-50;
  }
}

.tableContainer{
  border-radius: 20px;
  height: 510px;
  padding: 0 10px 10px 10px;
  overflow: auto;
}

Table thead{
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  z-index: 10;
}
